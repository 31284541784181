import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';

export enum DataPermissionType {
    STATE = 'STATE',
    TR = 'TR',
    LGA = 'LGA',
    SA2 = 'SA2',
    SA3 = 'SA3',
    SA4 = 'SA4',
    DN = 'DN',
    SETTING = 'SETTING',
    NRMR = 'NRMR',
    ILOC = 'ILOC',
    'STATE-SUBURBS' = 'STATE-SUBURBS',
    EXPLOREPARKS = 'EXPLOREPARKS',
    WDPA = 'WDPA',
    COUNTRY = 'COUNTRY',
    'NZ-COUNCIL' = 'NZ-COUNCIL',
    'NZ-RTO' = 'NZ-RTO',
    'PH-REGIONGS' = 'PH-REGIONGS',
    'PH-MUNICIPALITY' = 'PH-MUNICIPALITY',
    'ID-KABUPATEN' = 'ID-KABUPATEN',
}

export class DataPermission {
    id: number;
    type: DataPermissionType;
    keyID: number;
    keyText: string;
    title: string;
    grouping: string;
    country: string;
    isChecked?: boolean;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}

export function permissionToBoundary(p: DataPermission): Boundary {
    return {
        id: p.keyID,
        code: p.keyText,
        type: p.type,
        name:
            p.type == DataPermissionType.STATE || DataPermissionType.COUNTRY
                ? p.title
                : `${p.title}, ${p.grouping}`,
        grouping: p.grouping,
    };
}
