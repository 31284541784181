import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Setting } from '../../shared/setting.model';
import { SessionInfoState } from '../states/sessionInfo.state';
import { environment } from 'environments/environment';

export const selectSessionInfoState = createFeatureSelector<SessionInfoState>('sessioninfo');

export const selectOrganisationId = createSelector(
    selectSessionInfoState,
    (state) => state.organisation.id
);

export const selectCurrentUser = createSelector(selectSessionInfoState, (state) => state.user);

export const selectCurrentOrganisation = createSelector(
    selectSessionInfoState,
    (state) => state.organisation
);

export const selectCurrentOrganisationName = createSelector(
    selectSessionInfoState,
    (state) => state.organisation?.name
);

export const isImpersonating = createSelector(
    selectCurrentUser,
    (state) => state.originalOrganisationID != null
);

export const isSessionInfoLoaded = createSelector(
    selectSessionInfoState,
    (state) => state.isLoaded
);

/**
 * Get all the settings
 */
export const selectCurrentSettings = createSelector(
    selectSessionInfoState,
    (state) => state.setting
);

/**
 * Get a single setting with a given key
 */
export const selectCurrentSetting = () =>
    createSelector(selectCurrentSettings, (settings: Setting[], props: { key: string }) =>
        settings.filter((s) => s.key == props.key).shift()
    );

/* Custom selectors for specific oft-used settings */

export const selectSettingLngLat = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'latlng')
        .map((s) => s.keyObject)
        .shift();
    return val ? [...val.split(',').map(Number)] : [133.7751, -27.5];
});
export const selectSettingDefaultLocation = createSelector(selectCurrentSettings, (settings) => {
    return settings.filter((s) => s.key == 'latlng');
});

export const selectLightPresent = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'lightPresent')
        .map((s) => s.keyObject)
        .shift();
    return val ? val : 'day';
});

export const selectSettingZoom = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'zoom')
        .map((s) => s.keyObject)
        .shift();
    return val ? Number(val) : 3;
});

export const selectCustomIcon = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'siteicon')
        .map((s) => s.keyObject)
        .shift();
    return val;
});

export const geozivSetting = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'geoviz')
        .map((s) => s.keyObject)
        .shift();
    return val;
});

export const selectDefaultBoundary = createSelector(selectCurrentSettings, (settings) => {
    const boundary = {
        lat: -33.641967201499156,
        lng: 146.29951406249972,
        id: '1',
        name: 'New South Wales , NSW',
        code: '1',
    };
    const val = settings
        .filter((s) => s.key == 'defaultBoundary')
        .map((s) => s.keyObject)
        .shift();

    return val ? JSON.parse(val) : { boundaryType: 'STATE', boundaries: [boundary] };
});

export const selectHomepage = createSelector(selectSessionInfoState, (state) =>
    state.setting
        .filter((s) => s.key === 'homepage')
        .map((s) => s.keyObject)
        .shift()
);
export const selectAssetProfiler = createSelector(selectSessionInfoState, (state) =>
    state.setting
        .filter((s) => s.key === 'assetProfiler')
        .map((s) => s.keyObject)
        .shift()
);

export const selectCountry = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'country')
        .map((s) => s.keyObject)
        .shift();
    return val ? val : 'AU';
});

export const trafficFilters = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'trafficFilter')
        .map((s) => s.keyObject)
        .shift();
    return val ? Boolean(val) : false;
});

export const selectSalesCategory = createSelector(selectSessionInfoState, (state) =>
    state.setting
        .filter((s) => s.key === 'salesCategory')
        .map((s) => s.keyObject)
        .shift()
);

export const selectAvailabilityCategory = createSelector(selectSessionInfoState, (state) =>
    state.setting
        .filter((s) => s.key === 'availabilityCategory')
        .map((s) => s.keyObject)
        .shift()
);
export const selectTileServerUrl = createSelector(selectCurrentSettings, (settings) => {
    const val = settings
        .filter((s) => s.key == 'country')
        .map((s) => s.keyObject)
        .shift();

    if (val === 'AU' || val == '' || val == undefined || val == null) {
        return `${environment.tileserver.baseUrl}/capabilities/au-`;
    } else {
        return `${environment.tileserver.baseUrl}/capabilities/`;
    }
});

export const selectCurrentOrganisationDatasets = createSelector(
    selectSessionInfoState,
    (state) => state.dataset
);

export const selectMaxDatasetDateRange = createSelector(
    selectCurrentOrganisationDatasets,
    (datasets) => {
        const startDate = datasets.reduce((a, e) => (e.start_date < a.start_date ? e : a));
        const endDate = datasets.reduce((a, e) => (e.end_date > a.end_date ? e : a));
        return [startDate, endDate];
    }
);

// call using syntax like this
// this.deviceGPS = store.pipe(select(dataset.selectDataset('DEVICE_GPS')))
export const selectDataset = (key: string) =>
    createSelector(selectCurrentOrganisationDatasets, (state) =>
        state.filter((ds) => ds.key === key)
    );

// export const selectDatasetsState = createFeatureSelector<Dataset>("dataset")

export const selectDeviceGPS = createSelector(selectCurrentOrganisationDatasets, (state) =>
    state.filter((ds) => ds.key === 'DEVICE_GPS')
);

export const selectDigitalIntegration = createSelector(selectCurrentOrganisationDatasets, (state) =>
    state.filter((ds) => ds.key === 'DIGITAL_INT')
);

export const selectListings = createSelector(selectCurrentOrganisationDatasets, (state) =>
    state.filter((ds) => ds.key === 'LISTINGS')
);

export const selectTelco = createSelector(selectCurrentOrganisationDatasets, (state) =>
    state.filter((ds) => ds.key === 'TELCO')
);

export const selectTier1Bank = createSelector(selectCurrentOrganisationDatasets, (state) =>
    state.filter((ds) => ds.key === 'TIER1_BANK')
);

export const selectSentiment = createSelector(selectCurrentOrganisationDatasets, (state) =>
    state.filter((ds) => ds.key === 'SENTIMENT')
);

export const selectDomesticTransaction = createSelector(
    selectCurrentOrganisationDatasets,
    (state) => state.filter((ds) => ds.key === 'IFI_DOMESTIC_TRANSACTION')
);

export const selectInternationalTransaction = createSelector(
    selectCurrentOrganisationDatasets,
    (state) => state.filter((ds) => ds.key === 'IFI_INTERNATIONAL_TRANSACTION')
);
