import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { CompareRequest, CompareResponse } from './compare';
import { MembersRequest, MembersResponse } from './members';
import { SelectRequest, SelectResponse } from './select';
import { Observable, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CubesService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    constructor(private http: HttpClient) {}

    // Define a map to store cached responses
    private cachedResponses = new Map<string, Observable<MembersResponse>>();

    select(request: SelectRequest) {
        return this.http.post<SelectResponse>(
            `${this.apiBase}/cubes/select`,
            request,
            this.options
        );
    }

    compare(request: CompareRequest) {
        return this.http.post<CompareResponse>(
            `${this.apiBase}/cubes/compare`,
            request,
            this.options
        );
    }

    // members(request: MembersRequest) {
    //     return this.http.post<MembersResponse>(
    //         `${this.apiBase}/cubes/members`,
    //         request,
    //         this.options
    //     );
    // }
    members(request: MembersRequest): Observable<MembersResponse> {
        // Convert the request object to a unique identifier (e.g., JSON.stringify)
        const cacheKey = request.index + '_' + request.field.uniqueName;

        // Check if the response for this request is already cached
        if (this.cachedResponses.has(cacheKey)) {
            return this.cachedResponses.get(cacheKey)!; // Use non-null assertion operator (!)
        } else {
            // If not cached, make the HTTP request, cache the response, and return it
            const response$ = this.http.post<MembersResponse>(
                `${this.apiBase}/cubes/members`,
                request,
                this.options
            );

            this.cachedResponses.set(cacheKey, response$); // Cache the response
            return response$;
        }
    }
}
