import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MatDateFormats,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { AppComponent } from './app.component';
import { metaReducers, reducers } from './core/reducers';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { PipeModule } from './pipes/pipe.module';
import { PartialsModule } from './views/partials/partials.module';
import { ThemeModule } from './views/theme/theme.module';

// Layout Services
import {
    KtDialogService,
    LayoutConfigService,
    LayoutRefService,
    MenuAsideService,
    MenuConfigService,
    MenuHorizontalService,
    PageConfigService,
    SplashScreenService,
    SubheaderService,
} from './core/_base/layout';

// Auth
import { Auth0Service } from './core/auth';
import { AuthModule } from './views/pages/auth/auth.module';
// Config
import { LayoutConfig } from './core/_config/layout.config';

import { TokenInterceptor } from './core/auth/_services/token.interceptor';

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { DataPermissionsService } from './core/data-permissions/store';
import { DataSetService } from './core/dataset/store';
import { SessionInfoService } from './core/session-info/shared/session-info.service';
import { ExportPdfService } from './core/_base/layout/services/export-pdf.service';
import { ListingsService } from './views/pages/hotels/shared/listings.service';
import { DigitalIntegrationService } from './views/partials/content/digital-integration/shared/digital-integration.service';
import { MeasureService } from './views/partials/content/measures/store';
import { LoadingService } from './views/partials/layout/loading/loading.service';
import { OverlayService } from './views/partials/layout/overlay/overlay.service';
import { PrintService } from './views/partials/layout/print/print.service';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { WhitelabelService } from './core/whitelabel/shared/whitelabel.service';
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};

const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return () => {
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
        }),
        StoreDevtoolsModule.instrument(),
        AuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        ThemeModule,
        PipeModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.licenseKey),
    ],
    exports: [],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000,
                panelClass: ['snackbar'],
            },
        },
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        SplashScreenService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService, NgxPermissionsService],
            multi: true,
        },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        PrintService,
        ExportPdfService,
        LoadingService,
        OverlayService,
        Auth0Service,
        ListingsService,
        MeasureService,
        DataPermissionsService,
        SessionInfoService,
        DataSetService,
        DigitalIntegrationService,
        WhitelabelService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
